<template>
  <div class="page-view">
    <Offer />
  </div>
</template>

<script>
import Offer from "@/components/Offer.vue";
export default {
  name: "offer",
  components: {
    Offer
  },
  methods: {
    scroll: function() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  },
  beforeMount: function() {
    setTimeout(() => this.scroll(), 100);
  }
};
</script>

<style></style>
