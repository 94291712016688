<template>
  <footer>
    <div class="footer-container">
      <div class="footer-content">
        <div class="footer-middle">
          <div class="footer-middle-top">
            <a href="/">
              <img src="@/assets/logo.png" />
            </a>
          </div>
          <div class="footer-middle-bottom">
            <a
              class="text"
              href="https://www.facebook.com/WebVision.Art"
              target="_blank"
            >
              Facebook</a
            >
            <div class="icons">
              <a href="https://www.facebook.com/WebVision.Art" target="_blank"
                ><b-icon icon="facebook" scale="3"></b-icon
              ></a>
            </div>
            <a
              class="footer-social-media-middle"
              href="https://twitter.com/WebvisionA"
              target="_blank"
              ><span class="text"> twitter </span></a
            >
            <div class="icons">
              <a href="https://twitter.com/WebvisionA" target="_blank"
                ><b-icon icon="twitter" scale="3"></b-icon
              ></a>
            </div>
            <a
              class="text"
              href="https://www.instagram.com/webvision.art/"
              target="_blank"
              >instagram</a
            >
            <div class="icons">
              <a href="https://www.instagram.com/webvision.art/" target="_blank"
                ><b-icon icon="instagram" scale="3"></b-icon
              ></a>
            </div>
          </div>
        </div>
        <div class="footer-left">
          <ul>
            <li><a href="/">Strona główna</a></li>
            <li><router-link to="/offer">Oferta</router-link></li>
            <li><router-link to="/productions">Realizacje</router-link></li>
            <li><router-link to="/partnership">Współpraca</router-link></li>
            <li><router-link to="/team">Zespół</router-link></li>
          </ul>
        </div>
        <div class="footer-right">
          <ul>
            <li><a class="mail">Biuro@webvision.art</a></li>
            <li><a>+48 661 163 586</a></li>
            <li><a>8:00-20:00 PON-NDZ</a></li>
            <li><router-link to="/contact">Napisz do nas</router-link></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "footer"
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/footer/_footer.scss";
</style>
