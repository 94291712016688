<template>
  <section class="offer">
    <div style="float:left;margin-top:10px;margin-left:1%;"><Menu /></div>
    <div class="v-h">
      <div class="title-container">
        <h1 class="offer-header font-offer">Oferta</h1>
      </div>
      <div class="container-wrapper">
        <div
          class="first-heading "
          v-scrollanimation
          @click="scrollToElement('design-wrapper')"
        >
          <h2 class="font-offer">Projekty</h2>
          <h2 class="font-offer">Graficzne</h2>
        </div>
        <div
          class="second-heading"
          v-scrollanimation
          @click="scrollToElement('offer-content')"
        >
          <h2 class="font-offer">Strony/sklepy</h2>
          <h2 class="font-offer">Internetowe</h2>
        </div>
        <div
          class="third-heading"
          v-scrollanimation
          @click="scrollToElement('seo-wrapper')"
        >
          <h2 class="font-offer">Pozycjonowanie</h2>
          <h2 class="font-offer">Stron</h2>
        </div>
      </div>
    </div>
    <div>
      <div class="title-divider"></div>
      <transition>
        <div>
          <div class="title-wrapper">
            <div class="mini-logo">
              <a href="/">
                <span class="logo-font">
                  <span class="tablet-view">
                    Web
                  </span>
                  <img class="logo" src="../assets/logo.png" />
                  <span class="tablet-view">
                    Vision
                  </span>
                </span>
              </a>
            </div>
            <div class="title font-offer" v-scrollanimation>Oferta</div>
            <div class="ham-wrapper">
              <div class="ham"><Menu /></div>
            </div>
          </div>
          <div class="line"></div>
        </div>
      </transition>
      <div class="nd-line"></div>
      <div class="offer-content ">
        <div class="text-wrapper">
          <div class="text-container" v-scrollanimation>
            <span class="text">
              Współczesne strony internetowe muszą być w pełni dopasowane do
              potrzeb użytkowników, którzy stają się coraz bardziej wymagający.
              Oferujemy wykonanie strony internetowej bądź sklepu, z którego
              będziecie zadowoleni, zarówno Ty jak i Twoi użytkownicy. Dzięki
              naszym usługom wzniesiemy Twoją działalność na wyższy poziom.
            </span>
          </div>
        </div>
        <div class="content-divider"></div>
        <div class="content-title tablet-title " v-scrollanimation>
          <span class="title-font" style="display:inline-block">Strony</span>
          <span class="circle"></span>
          <span class="title-font nd-part">Internetowe</span>
        </div>
      </div>
      <div class="lower-divider"></div>
      <div class="vertical-lower-divider"></div>

      <div class="title-divider"></div>
      <div>
        <div class="title-wrapper">
          <div class="mini-logo">
            <a href="/">
              <span class="logo-font">
                <span class="tablet-view">
                  Web
                </span>
                <img class="logo" src="../assets/logo.png" />
                <span class="tablet-view">
                  Vision
                </span>
              </span>
            </a>
          </div>
          <div class="title font-offer" v-scrollanimation>Oferta</div>
          <div class="ham-wrapper">
            <div class="ham"><Menu /></div>
          </div>
        </div>
        <div class="line"></div>
      </div>
    </div>

    <div class="nd-vertical-lower-divider"></div>
    <div class="design-wrapper">
      <div class="photo-wrapper">
        <div class="design-img"></div>
      </div>
      <div class="design-text-wrapper">
        <div class="content-title " v-scrollanimation>
          <span
            class="title-font h-design mobile-design"
            style="display:inline-block"
            >Projekty</span
          >
          <span class="title-font nd-part mobile-design-nd-part"
            >Graficzne</span
          >
        </div>
        <div class="circles">
          <span class="nd-circle"></span>
          <span class="nd-circle"></span>
        </div>
        <div class="nd-text-wrapper" v-scrollanimation>
          <span class="nd-text">
            Podobno pierwsze wrażenie jest najważniejsze. Wykonamy dla Ciebie
            projekt strony internetowej, logo wraz z księgą znaków oraz szeroko
            rozumiane projekty graficzne na potrzeby marketingu. Jeżeli zależy
            ci na zwróceniu uwagi potencjalnych klientów na Twoją ofertę, nasza
            agencja Ci w tym pomoże.
          </span>
        </div>
      </div>
    </div>
    <div class="lower-break"></div>
    <div class="lower-mobile-divider"></div>
    <div class="lower-vertical-mobile-divider"></div>
    <div class="tablet-divider"><div class="title-divider"></div></div>
    <div>
      <div class="title-wrapper">
        <div class="mini-logo">
          <a href="/">
            <span class="logo-font">
              <span class="tablet-view">
                Web
              </span>
              <img class="logo" src="../assets/logo.png" />
              <span class="tablet-view">
                Vision
              </span>
            </span>
          </a>
        </div>
        <div class="title font-offer" v-scrollanimation>Oferta</div>
        <div class="ham-wrapper">
          <div class="ham"><Menu /></div>
        </div>
      </div>
      <div class="line"></div>
    </div>

    <div>
      <div class="seo-wrapper">
        <div class="seo-text-wrapper">
          <div class="content-title h-seo-wrapper" v-scrollanimation>
            <span class="title-font h-seo h-seo-mobile">Pozycjonowanie</span>
            <span class="title-font h-design h-seo">stron</span>
            <span class="title-font h-design h-seo tablet-h">w</span>
            <span class="title-font nd-part seo">wyszukiwarkach</span>
          </div>
          <div style="margin-top:20px;">
            <span class="rd-circle"></span>
            <span class="rd-circle"></span>
            <span class="rd-circle"></span>
          </div>
          <div class="rd-text-wrapper" v-scrollanimation>
            <span class="nd-text">
              Wysoka pozycja Twojej strony w wyszukiwarkach internetowych jest
              bardzo znaczącym elementem biznesu. Zaufaj nam i daj się dostrzec
              na pierwszej pozycji.
            </span>
          </div>
        </div>
        <div class="rd-vertical-line"></div>
        <div class="seo-img-wrapper">
          <div class="seo-img"></div>
        </div>
      </div>
      <div class="lower-break"></div>
      <div class="lower-vertical-break"></div>
    </div>
    <footerm />
  </section>
</template>

<script>
import footerm from "@/components/footer.vue";
import Menu from "@/components/MenuBurger.vue";
export default {
  name: "Offer",
  components: {
    Menu,
    footerm
  },
  methods: {
    scrollToElement(name) {
      const element = this.$el.getElementsByClassName(name)[0];

      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/Offer/_Offer.scss";
@import "@/assets/styles/Offer/responsive/xxlarge.scss";
@import "@/assets/styles/Offer/responsive/xlarge_D.scss";
@import "@/assets/styles/Offer/responsive/large_D.scss";
@import "@/assets/styles/Offer/responsive/medium_D.scss";
@import "@/assets/styles/Offer/responsive/small_D.scss";
@import "@/assets/styles/Offer/responsive/xsmall_D.scss";
</style>
